@if (isDocumentCreateRequest()) {
    <div class="border border-black rounded-[50px] document-request-tag w-[8rem] mr-1 mb-1 flex items-center justify-between">
        <label class="block truncate">{{document.fileName}}</label>
        <img [src]="images.close" class="cursor-pointer h-4 w-4" (click)="onDelete($event)"/>
    </div>    
} @else {
    <div class="bg-white border border-black rounded-[50px] document-tag w-[150px] flex items-center justify-between" (click)="onClick()">
        <label class="block truncate cursor-pointer">{{document.fileName}}</label>
        <img [src]="images.close" class="cursor-pointer h-4 w-4" (click)="onDelete($event)"/>
    </div>
}