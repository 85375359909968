import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import {
  IApps,
  IBase,
  IClient,
  IOperator,
  ITown,
  IUserDriver,
  IUserRegular,
} from 'src/app/models';
import {
  Gender,
  InputType,
  NotificationTypes,
  UserRole,
  UserStatus,
  UserType,
} from 'src/app/models/enums';
import {
  NotificationService,
  StorageService,
  UsersService,
  UtilsService,
  ServicesService,
  RoleService
} from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { TooltipPosition } from 'src/app/shared/tooltip/tooltip-position';
import { flags, images } from 'src/images';
import {
  getNotificationsValues,
  getUserDriverForm,
  getUserRegularForm,
} from 'src/app/forms/userForms';
import { Modal } from 'flowbite';
import { Router, NavigationStart } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  @Input() user: any = {} as IUserRegular | {} as IUserDriver;
  @Input() newUser: boolean;
  @Output() closeDetails = new EventEmitter();
  @Output() userEdited = new EventEmitter<IUserRegular | IUserDriver>();
  @Output() showViewBookingsEventEmitter = new EventEmitter();
  @Output() showDocumentsModalEventEmitter = new EventEmitter();
  @Output() showChangeStatusEventEmitter = new EventEmitter();
  @Output() showFavourites = new EventEmitter();
  public userCopy: any = {} as IUserRegular | {} as IUserDriver;
  public TooltipPosition = TooltipPosition;
  public images = images;
  public userStatus = UserStatus;
  public userType = UserType;
  public userRole = UserRole;
  public expanded = false;
  public selectedClients: IBase[] = [];
  public edit = false;
  public editStatus = false;
  public adminRole: UserRole;

  public shownUserId?: number;

  private routerSubscription: Subscription;

  public InputType = InputType;
  public genders: Gender[] = [];
  public userRoles: UserRole[] = [];
  public userStatusList: any[] = [];
  public languages: unknown[] = [];
  public notifications: unknown[] = [];
  public clients: IClient[] = [];
  public towns: ITown[] = [];
  public operators: IOperator[] = [];
  public notificationsValues: unknown[] = [];

  public flags = flags;

  public policyAcceptedIsChecked = false;

  public userForm: any[];

  @ViewChild('form') form: ElementRef;
  public addToAppModal: Modal;
  public changePasswordModal: Modal;

  public userFormControl: FormGroup;
  public invalid: any[] = [];

  public phoneObject = { prefix: '+34', phone: '' };

  public addToAppShowed: boolean;
  public changePasswordShowed: boolean;

  public password = '';
  public confirmPassword = '';
  public changingPassword = false;

  public apps: IApps[] = [];

  constructor(
    public literalService: LiteralService,
    public usersService: UsersService,
    public utilsService: UtilsService,
    public notificationService: NotificationService,
    public router: Router,
    public storageService: StorageService,
    private clipboardService: ClipboardService,
    private servicesService: ServicesService,
    public roleService: RoleService
  ) {}

  async ngOnInit() {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Close the modal when navigating away from the current view
        this.closeModals();
      }
    });
  }

  async ngAfterViewInit() {
    this.adminRole = await this.storageService.getRole();
    if (!this.newUser && this.user) this.shownUserId = this.user.id;

    this.user && this.user.type === UserType.Regular
      ? getUserRegularForm(
          this.user as IUserRegular,
          this.literalService,
          this.usersService,
          this.storageService
        ).then((res: any) => {
          this.userForm = res;
        })
      : getUserDriverForm(
          this.user as IUserDriver,
          this.literalService,
          this.usersService,
          this.storageService
        ).then((res: any) => {
          this.userForm = res;
        });

    this.resetClientValues();

    this.initializeModals();
  }

  async ngOnChanges() {
    if (this.newUser) {
      this.shownUserId = undefined;
    } else if (this.user && this.user.id != this.shownUserId) {
      this.edit = false;
      this.editStatus = false;
      this.shownUserId = this.user.id;
    }

    this.user && this.user.type === UserType.Regular
      ? getUserRegularForm(
          this.user as IUserRegular,
          this.literalService,
          this.usersService,
          this.storageService
        ).then((res: any) => {
          this.userForm = res;
        })
      : getUserDriverForm(
          this.user as IUserDriver,
          this.literalService,
          this.usersService,
          this.storageService
        ).then((res: any) => {
          this.userForm = res;
        });

    this.resetClientValues();

    this.userStatusList = [];
    this.orderUserStatusDropdown();
    this.apps = [];
    if (this.user?.type === UserType.Regular) {
      const appsArray =
        this.user?.id && (await this.usersService.getUserApps(this.user.id));
      appsArray &&
        appsArray.map((app: IApps) => {
          this.apps.push(app);
        });
    }
    // this.user?.id && this.initializeModals();
  }

  private async initializeModals() {
    this.changePasswordModal = new Modal(document.getElementById('changePasswordModal'), {
        placement: 'center',
        closable: true,
        onHide: () => {
          this.changePasswordShowed = false;
          document.getElementById('changePassword')!.style.zIndex = '0';
        },
        onShow: () => {
          this.changePasswordShowed = true;
        },
      },
    );

    this.addToAppModal = new Modal(document.getElementById('addToAppModal'), {
      placement: 'center',
      closable: true,
      onHide: () => {
        this.addToAppShowed = false;
        document.getElementById('addToApp')!.style.zIndex = '0';
      },
      onShow: () => {
        this.addToAppShowed = true;
      },
    });
  }

  closeModals() {
    if (this.changePasswordShowed) this.changePasswordModal.toggle();
    if (this.addToAppShowed) this.addToAppModal.toggle();
  }

  async resetClientValues() {
    await this.getClientsTowns().then((res) => {
      this.clients = res.clients;
      this.towns = res.towns;
      const clientsIds = this.user && this.user.clients?.map((client: IClient) => {
        return client.id;
      }) || [];
      clientsIds.length > 0 && this.updateClientOperators(clientsIds);
      if (this.user) {
        if (this.clients.length <= 1) { // if only one, auto-select client and first town
          this.user.clients = this.clients;
          if (!this.user.town || !this.towns.find((town: ITown) => town.id === this.user.town)) {
            this.user.town = this.towns[0];
          }
        } else {
          const selectedClientsIds = this.user && this.user.clients?.map((client: IClient) => {
              return client.id; 
            }) || [];
          selectedClientsIds.length > 0 && this.updateTowns(selectedClientsIds);
          selectedClientsIds.length > 0 && this.updateClientOperators(selectedClientsIds);
        }
      }
    });
  }

  async updateTowns(selectedClientsIds: number[]) {
    await this.usersService
      .getTownsByClient(selectedClientsIds)
      .then((res: any) => {
        this.towns = res;
        if (!this.user.town || !this.towns.find((town: ITown) => town.id === this.user.town.id)) {
          this.user.town = this.towns[0];
        }
      });
  }

  async updateClientOperators(clientIds: number[]) {
    await this.usersService
      .getOperatorsByClient(clientIds)
      .then((res: any) => {
        this.operators = res;
        if (this.user.type == UserType.Driver) {
          if (!this.user.operator || !this.operators.find(this.user.operator)) {
            this.user.operator = this.operators[0];
          }
        }
      });
  }

  async getClientsTowns() {
    return await this.usersService.getPossibleClientTowns().then((res) => {
      const clients: any[] = [];
      const towns: any[] = [];
      res.clients.map((client: IClient) => {
        clients.push(client);
      });
      res.towns.map((town: ITown) => {
        towns.push(town);
      });
      return { clients: clients, towns: towns };
    });
  }

  editStatusClick = () => {
    this.editStatus = !this.editStatus;
  };

  async changeStatus(data: any) {
    this.showChangeStatusEventEmitter.emit(data.value);
    this.editStatus = false;
  }

  editAction = async() => {
    this.edit = !this.edit;
    this.userCopy = {
      ...this.user,
      clients: {...this.user.clients},
      sms: {...this.user.sms},
      sendEmail: {...this.user.sendEmail},
      push: {...this.user.push}
    };
    this.phoneObject = { prefix: '+34', phone: '' };
  };

  changeData(data: any, element?: any) {
    if (element?.edit.valueToChange === 'clients') {
      this.ngOnChanges();
    } else if (element?.edit.valueToChange === 'town') {
      return this.towns.find((town: ITown) => data.value == town.id);
    } else if (element?.edit.valueToChange === 'operator') {
      return this.operators.find((operator: IOperator) => data.value === operator.id);
    } else if (element?.edit.valueToChange === 'phone') {
      return this.setPhoneData(data, this.user.phone);
    } else if (element?.edit.valueToChange === 'additionalPhone') {
      return this.setPhoneData(data, this.user.additionalPhone);
    } else if (Array.isArray(element?.edit.valueToChange)) {
      this.setMultiSelectData(data, element);
    }
    return this.setNormalData(data);
  }

  private setPhoneData(data: any, phone: any) {
    if (!phone) {
      if (data.tagName === 'INPUT') {
        this.phoneObject.phone = data.value;
      } else if (data.tagName === 'SELECT') {
        this.phoneObject.prefix = data.value;
      }
    } else {
      const prefixRegEx =
        /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
      this.phoneObject.prefix = `+${phone.match(prefixRegEx)[1]}`;
      this.phoneObject.phone = phone;
      this.phoneObject.phone = this.phoneObject.phone.replace(
        this.phoneObject.prefix,
        '',
      );
      if (data.tagName === 'INPUT') {
        this.phoneObject.phone = data.value;
      } else if (data.tagName === 'SELECT') {
        this.phoneObject.prefix = data.value;
      }
    }
    return this.phoneObject.prefix + this.phoneObject.phone;
  }

  private setNormalData(data: any) {
    if (this.isValidDate(data.value)) {
      return moment(data.value).format('yyyy-MM-DD');
    }
    return data.value;
  }

  public getPenalizedEndDate(user: IUserRegular) {
    let response = '';
    response = user.penaltyEndDate ? `<strong>${this.literalService.get('users.penalizedUntil', true)}</strong><br>${moment(user.penaltyEndDate).format('DD/MM/YYYY')}` : this.literalService.get('users.actions.Blocked.name', true);
    response += `<br><br><strong>${this.literalService.get('users.actions.Blocked.reasonTitle', true)}:</strong><br>${user.penaltyReason ? user.penaltyReason : this.literalService.get('notSpecified', true)}`;
    return response;
  }

  public showDistanceTravelled(user: IUserRegular) {
    return `<strong>${this.literalService.get('users.actions.' + user.status + '.name', true)}</strong><br/>${this.literalService.get('users.data.distanceTravelled', true)}: ${user.distanceTravelled!.toFixed(2)}`;
  }

  private setMultiSelectData(data: any, element: any) {
    if (data.type === 'checkbox') {
      const valueToChange = element?.edit.valueToChange.find((value: any) => {
        return value.toLowerCase().includes(data.value.toLowerCase());
      });
      this.user[valueToChange] = data.checked;
      this.userForm.forEach((element: any) => {
        if (element.title === 'users.data.notifications') {
          element.edit.selectedValues = getNotificationsValues(this.user);
        }
      });
    }
  }

  public isValidDate(d: any) {
    return d instanceof Date && !isNaN(Number(d));
  }

  public showAddToApp() {
    document.getElementById('addToApp')!.style.zIndex = '50';
    this.addToAppModal.toggle();
  }

  public showChangePassword() {
    document.getElementById('changePassword')!.style.zIndex = '50';
    this.changePasswordModal.toggle();
  }

  public close() {
    this.expanded = false;
    this.edit = false;
    this.editStatus = false;
    this.newUser = false;
    this.closeDetails.emit();
  }

  public async findInvalidControls() {
    this.userFormControl = new FormGroup({
      firstName: new FormControl(this.user.firstName, [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      clients: new FormControl(this.user.clients, [Validators.required]),
      //operator: new FormControl(this.user.operator, [Validators.required]),
    });
    this.user.type === UserType.Driver && this.userFormControl.addControl('operator', new FormControl(this.user.operator, [Validators.required]));
    this.user.type === UserType.Driver && this.userFormControl.addControl('username', new FormControl(this.user.username, [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]));
    if (this.user.type == UserType.Regular) {
      !this.user.phone &&
        this.userFormControl.addControl(
          'email',
          new FormControl(this.user.email, [
            Validators.required,
            Validators.email,
            Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
          ]),
        );
      !this.user.email &&
        this.userFormControl.addControl(
          'phone',
          new FormControl(this.user.phone, [
            Validators.required,
            Validators.pattern(/^\+(?:\d{1,3})?(?:[ -]?\d{1,4}){1,14}$/),
          ]),
        );
    }

    this.invalid = [];
    const controls = this.userFormControl.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        this.invalid.push(name);
      }
    }
  }

  showError(value: string, field: string) {
    let errorText = '';
    this.invalid.map((invalidField: string) => {
      if (invalidField === field) {
        if (!this.user[invalidField] || this.user[invalidField].length === 0) {
          if (invalidField === "email" || invalidField === "phone") errorText = this.literalService.get('requiredFieldOrNext', true);
          else errorText = this.literalService.get('errors.requiredField', true);
        } else {
          errorText = this.literalService.get('errors.incorrectField', true);
        }
      }
    });
    return errorText;
  }

  async onSubmit() {
    await this.findInvalidControls();
    if (this.invalid.length === 0) {
      if (this.edit) {
        console.log(this.userCopy, this.user);
        const diffData = this.utilsService.compareObjects(
          this.userCopy,
          this.user,
        );
        const data = { id: this.user.id, type: this.user.type } as any;
        diffData.map((d: any) => (data[d] = this.user[d]));
        if (data.phone && data.phone.length < 4) data.phone = ''; //It only contains phone prefix like +34
        console.log(data);
        this.user = await this.usersService.editUser(data);
      } else {
        this.user = await this.usersService.createUser(this.user);
      }
      console.log(this.user);
      this.notificationService.image = images.sidebar.users;
      this.notificationService.title = this.literalService.get(
        `users.actions.${this.edit ? 'edit' : 'create'}.title`,
        true,
      );
      this.notificationService.message = this.literalService.get(
        `users.actions.${this.edit ? 'edit' : 'create'}.text`,
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
      this.edit = false;
      this.newUser = false;
      this.userEdited.emit(this.user);
      this.ngOnChanges();

      // TODO this should be better done redirecting to the details and going through ngOnChanges
      // TODO need to also set apps for the apps modal to not be empty
      this.initializeModals();
    }
  }

  public isExpandedFormInvalid(invalidArray: string[]) {
    return this.userForm && this.userForm.some(form => form.expanded && form.edit && form.edit.valueToChange && invalidArray.includes(form.edit.valueToChange));
  }

  public async changePassword(password: string) {
    this.changingPassword = true;
    await this.usersService.changePassword(this.user.id, password).then(() => {
      this.notificationService.image = images.user.password;
      this.notificationService.title = this.literalService.get(
        'users.actions.changePassword.passwordChangedSuccessTitle',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'users.actions.changePassword.passwordChangedSuccessText',
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
    }, () => {
      this.notificationService.image = images.user.password;
      this.notificationService.title = this.literalService.get(
        'users.actions.changePassword.passwordChangedErrorTitle',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'users.actions.changePassword.passwordChangedErrorText',
        true,
      );
      this.notificationService.show(NotificationTypes.DANGER);
    });
    this.changePasswordModal.toggle();
    this.changingPassword = false;
  }

  public async addToApp(event: number) {
    const res = await this.usersService.addToApp(this.user.id, event);
    console.log(res);
    this.notificationService.image = images.sidebar.users;
    this.notificationService.title = this.literalService.get(
      'users.actions.create.title',
      true,
    );
    this.notificationService.message = this.literalService.get(
      'users.actions.create.text',
      true,
    );
    this.notificationService.show(NotificationTypes.SUCCESS);
    this.addToAppModal.toggle();
  }

  private orderUserStatusDropdown() {
    if (this.user && this.user.status && this.user.type && this.adminRole && this.user.status.toUpperCase() != UserStatus.DELETED) {
      this.userStatusList.push({ id: UserStatus.ACTIVE, name: UserStatus.ACTIVE.toUpperCase() });
      this.userStatusList.push({ id: UserStatus.INACTIVE, name: UserStatus.INACTIVE.toUpperCase() });
      if (this.user.type == UserType.Regular) {
        this.userStatusList.push({ id: UserStatus.BLOCKED, name: UserStatus.BLOCKED.toUpperCase() });
      }
      if (this.user.status.toUpperCase() == UserStatus.INACTIVE && (this.adminRole == 'SUPERADMIN' || this.adminRole == 'ADMIN')) {
        this.userStatusList.push({ id: UserStatus.DELETED, name: UserStatus.DELETED.toUpperCase() });
      }
    }
  }

  goToSelfBookings() {
    this.storageService.setUserSelected(this.user.id);
    this.router.navigate(['/bookings']);
  }

  copyLink(userId: number): void {
    const domain = this.servicesService.getCurrentDomain();
    this.clipboardService.copyFromContent(domain + '/users/' + userId);
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}

