<section>
    <div class="tripRow justify-center items-center h-16 grid grid-cols-11 ml-4 mr-4 mb-2 text-center border rounded last:mb-4" [ngClass]="{'rowStyle': index % 2 !== 0 }" 
    [ngClass]="{
        'even': index % 2 === 0 ,
        'ready': trip.status === 'Ready',
        'ongoingOk': trip.status === 'Ongoing' && !isLate(trip.delay) && !isEarly(trip.delay),
        'ongoingLate': trip.status === 'Ongoing' && isLate(trip.delay),
        'ongoingEarly': trip.status === 'Ongoing' && isEarly(trip.delay)
      }">
        <div class="flex justify-center items-center col-span-4 h-12 cursor-pointer" (click)="getTripEventEmitter.emit(trip.id!)">
            @if (trip.service && trip.service.tag.trim() !== '') {
                <app-service-tag class="flex justify-center items-center ml-4" [serviceInfo]="trip.service"></app-service-tag>
            }
            <div class="w-full">
                <label class="cursor-pointer mr-1 ml-1">{{ trip.name }}</label>
            </div>
        </div>
        <label class="flex justify-center items-center col-span-1 h-12 cursor-pointer pr-1 pl-1" (click)="getTripEventEmitter.emit(trip.id!)">
            {{ trip.dateTime | date: 'HH:mm dd/MM' }}
        </label>
        <label class="flex justify-center items-center col-span-2 h-12 cursor-pointer pr-1 pl-1" (click)="getTripEventEmitter.emit(trip.id!)">
            {{ trip.driver.firstName + ' ' + trip.driver.lastName }}
        </label>
        <label class="flex justify-center items-center col-span-2 h-12 cursor-pointer pr-1 pl-1" (click)="getTripEventEmitter.emit(trip.id!)">
            {{ trip.vehicle.name }}
        </label>
        <label [id]="'tripMoreOptions' + trip.id" class="flex justify-center items-center col-span-2 h-12">
            <div class="cursor-pointer h-6 w-6 mr-4 ml-2" (click)="showViewBookingsEventEmitter.emit(trip.id)" data-modal-target="viewBookings" data-modal-toggle="viewBookings"  [tooltip]="literalService.get('trips.actions.viewBookings', true)" [position]="TooltipPosition.ABOVE" >
                <img [src]="images.trips.date"/>
            </div>
            <div class="cursor-pointer h-6 w-6 mr-2" (click)="moreInfo($event, trip)" [tooltip]="literalService.get('trips.actions.otherOptions', true)" [position]="TooltipPosition.ABOVE" >
                <img [src]="images.moreInfo" alt="moreInfo"/>
            </div>
        </label>
    </div>
    <div [id]="'actionsModal' + trip.id" class="absolute w-52 primary-green-2-background z-10 rounded-lg" [ngClass]="{'fadeIn': showOptions, 'fadeOut': !showOptions}">
        <div class="pt-2 pb-2">
            <button (click)="copyLink(trip.id!)" class="flex w-full pl-2 mb-2 p-1 cursor-pointer hover:bg-white items-center hover:text-[#8136DB] actionRow">
                <img [src]="images.link" class="h-6 w-6 mr-2" />
                <label class="cursor-pointer">{{ literalService.get('copyLink', true) }}</label>
            </button>
            @if (roleService.permissions['EXPEDITION']['UPDATE']) {
                <button (click)="showChangeDriverEventEmitter.emit(trip.id)" data-modal-target="changeDriver" data-modal-toggle="changeDriver"  class="flex w-full pl-2 mb-2 p-1 cursor-pointer hover:bg-white items-center hover:text-[#8136DB] actionRow">
                    <img [src]="images.trips.driver" class="h-6 w-6 mr-2" />
                    <label class="cursor-pointer">{{ literalService.get('trips.actions.changeDriver.name', true) }}</label>
                </button>
                <button (click)="showChangeVehicleEventEmitter.emit(trip.id)" data-modal-target="changeVehicle" data-modal-toggle="changeVehicle" class="flex w-full pl-2 p-1 cursor-pointer hover:bg-white items-center hover:text-[#8136DB] actionRow">
                    <img [src]="images.trips.vehicle" class="h-6 w-6 mr-2" />
                    <label class="cursor-pointer">{{ literalService.get('trips.actions.changeVehicle.name', true) }}</label>
                </button>
                @if (trip.status === 'Open') {
                    <button (click)="showSetReadyEventEmitter.emit(trip.id)" data-modal-target="setReady" data-modal-toggle="setReady" class="flex w-full pl-2 p-1 mt-2 cursor-pointer hover:bg-white items-center hover:text-[#8136DB] actionRow">
                        <img [src]="images.trips.open" class="h-6 w-6 mr-2" />
                        <label class="cursor-pointer">{{ literalService.get('trips.actions.setReady.name', true) }}</label>
                    </button>
                } @else if (trip.status === 'Ready' || trip.status === 'Ongoing') {
                    <button (click)="showSetClosedEventEmitter.emit(trip.id)" data-modal-target="setClosed" data-modal-toggle="setClosed" class="flex w-full pl-2 p-1 mt-2 cursor-pointer hover:bg-white items-center hover:text-[#8136DB] actionRow">
                        <img [src]="images.trips.close" class="h-6 w-6 mr-2" />
                        <label class="cursor-pointer">{{ literalService.get('trips.actions.setClosed.name', true) }}</label>
                    </button>
                }
                @if (trip.status !== 'Closed' && trip.status !== 'Deleted' ) {
                    <button (click)="showDeleteTripEventEmitter.emit(trip.id)" data-modal-target="deleteTrip" data-modal-toggle="deleteTrip" class="flex w-full pl-2 p-1 mt-2 cursor-pointer hover:bg-white items-center hover:text-[#8136DB] actionRow">
                        <img [src]="images.trips.delete" class="h-6 w-6 mr-2" />
                        <label class="cursor-pointer">{{ literalService.get('trips.actions.deleteTrip.name', true) }}</label>
                    </button>
                }
            } 
        </div>
    </div>
</section>