@if (data.img && data.title) {
    <div class="flex items-center mb-2">
        <img [src]="data.img" [ngClass]="{'noColor': !data.color}" alt="detailImage" class="h-5 w-5 mr-1" />
        <label class="text-base font-normal" [hidden]="data.informativeToNoChange">{{ literalService.get(data.title, true) }}{{ data.edit.required && '*' || ''}}</label>
        <label class="text-base font-normal" [hidden]="!data.informativeToNoChange">{{ data.value && data.value.firstName + ' ' + data.value.lastName }}</label>
    </div>
}
<div [ngClass]="{'w-72': !independent, 'w-full': independent}" [hidden]="data.informativeToNoChange">
    <ng-container *ngIf="(data.edit.inputType === InputType.TEXT || data.edit.inputType === InputType.EMAIL || data.edit.inputType === InputType.TIME) && (edit || independent)">
        <div class="flex justify-end items-center relative">
            <input [type]="data.edit.inputType" [value]="data.value" (click)="cancelEvent($event)" class="block w-full h-12 px-3 py-2 bg-white border rounded-s rounded-e text-base font-normal shadow-sm placeholder-slate-400 focus:outline-none" (change)="changeEventEmitter.emit($event)" [required]="data.edit.required" [disabled]="data.edit.disabled" [ngClass]="{'invalid': hasErrors}" />
            <img [src]="images.error" class="absolute mr-2 w-6" alt="errorIcon" *ngIf="hasErrors" />
        </div>
        <label class="text-xs hasErrors" *ngIf="hasErrors">{{ error }}</label>
        <label class="text-xs neutrals-grey-04-text" *ngIf="!hasErrors && data.edit.inputType === InputType.EMAIL">(*) {{ literalService.get('requiredFieldOrNext', false) }}</label>
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.PHONE && (edit || independent)">
        <div class="flex justify-end items-center relative">
            <app-select class="flex-auto w-48 z-10" [translateValue]="translateValue" [elements]="countries" [id]="dialCodeCountry" [image]="true" [value]="dialCodeCountry" (change)="changeEventEmitter.emit($event)" [hasErrors]="hasErrors" />
            <input [type]="data.edit.inputType" [value]="data.value || ''" (change)="changeEventEmitter.emit($event)" class="block w-full h-12 -ml-2 z-0 px-3 py-2 bg-white border rounded-s rounded-e  text-base font-normal shadow-sm placeholder-slate-400 focus:outline-none" [required]="data.edit.required" [disabled]="data.edit.disabled" [ngClass]="{'invalid': hasErrors}" />
            <img [src]="images.error" class="absolute mr-2 w-6" alt="errorIcon" *ngIf="hasErrors" />
        </div>
        <label class="text-xs hasErrors" *ngIf="hasErrors">{{ error }}</label>
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.SELECT && (edit || independent)">
        <app-select [elements]="data.edit.values" [value]="data.value" [id]="data.edit.id" [translateValue]="translateValue" (change)="changeEventEmitter.emit($event)" [required]="data.edit.required" [disabled]="data.edit.disabled" [hasErrors]="hasErrors" [error]="error" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.MULTISELECT && (edit || independent)">
        <app-multi-select [title]="data.title" [elements]="data.edit.values" [values]="data.edit.selectedValues" [width]="'72'" [required]="data.edit.required" [disabled]="data.disabled" [hasErrors]="hasErrors" [error]="error" (change)="changeEventEmitter.emit($event)" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.DATE && (edit || independent)">
        <app-calendar [title]="data.title" [value]="data.value" [required]="data.edit.required" [disabled]="data.edit.disabled" [showOnTop]="data.showOnTop" [onlyFutureDays]="data.onlyFutureDays" [multipleDates]="data.multipleDates" [hideCalendar]="data.hideCalendar" (change)="change($event)" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.INFINITE_SCROLL && (edit || independent)">
        <select2 class="block" [data]="data.edit.values" [value]="data.value" infiniteScroll="true" customSearchEnabled="true" (update)="setEventEmitter.emit($event)" (scroll)="scrollEventEmitter.emit($event)" (search)="searchSelect2($event)" [disabled]="data.edit.disabled" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.SELECT_SEARCH && (edit || independent)">
        <select2 class="block" [data]="data.edit.values" [value]="data.value" [disabled]="data.edit.disabled" (update)="setEventEmitter.emit($event)" (search)="searchEventEmitter.emit($event)" />
    </ng-container>

    <ng-container *ngIf="data.edit.inputType === InputType.SEATS && (edit || independent)">
        <app-seats-input class="block ml-20 mr-20" [data]="data.edit.values" [value]="data.value" (changeEventEmitter)="changeEventEmitter.emit($event)" />
    </ng-container>
</div>

