import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IBase, IBookingCreate } from 'src/app/models';
import { ExpeditionStatus, ExpeditionType } from 'src/app/models/enums';
import { objectToCamel } from 'ts-case-convert';
import { BookingsHttpService } from './bookings.http.service';
import { IBookingUpdate } from 'src/app/models/bookings/bookingUpdate';
import { IBookingDetails } from 'src/app/models/bookings/bookingDetails';

@Injectable({
  providedIn: 'root',
})
export class BookingsService {
  constructor(private bookingsHttpService: BookingsHttpService) {}

  async getBookings(size: number, page?: number) {
    const source$ = this.bookingsHttpService.getBookings(size, page);
    const response = await lastValueFrom(source$);
    const bookingArray = this.getData(response);
    return bookingArray;
  }

  async getBookingsFiltered(
    size: number,
    page: number,
    expeditionClients: IBase[],
    expeditionTowns: IBase[],
    expeditionTypes: ExpeditionType[],
    expeditionStatus: ExpeditionStatus[],
    searchValue?: string
  ) {
    const source$ = this.bookingsHttpService.getBookingsFiltered(
      size,
      page,
      expeditionClients,
      expeditionTowns,
      expeditionTypes,
      expeditionStatus,
      searchValue
    );
    const response = await lastValueFrom(source$);
    const bookingArray = this.getData(response);
    return bookingArray;
  }

  async getBooking(bookingId: number) {
    const source$ = this.bookingsHttpService.getBooking(bookingId);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }

  async createBooking(createBookingRequest: IBookingCreate) {
    const source$ = this.bookingsHttpService.createBooking(createBookingRequest);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }
  
  async updateBooking(updateBookingRequest: IBookingUpdate)  {
    const source$ = this.bookingsHttpService.updateBooking(updateBookingRequest);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as IBookingDetails);
    return booking;
  }

  async deleteBooking(bookingId: number, comment: string) {
    const source$ = this.bookingsHttpService.deleteBooking(bookingId, comment);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }

  async updateMissed(bookingId: number) {
    const source$ = this.bookingsHttpService.updateMissed(bookingId);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }
  
  async updateKms(bookingId: number, kms: number) {
    const source$ = this.bookingsHttpService.updateKms(bookingId, kms);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as IBookingDetails);
    return booking;
  }

  async updateNotified(bookingId: number, isNotified: boolean) {
    const source$ = this.bookingsHttpService.updateNotified(bookingId, isNotified);
    const response = await lastValueFrom(source$);
    const booking = objectToCamel(response as object);
    return booking;
  }

  async isExceedingKmLimit(userId: number, inStopId?: number, outStopId?: number, serviceId?: number) {
    if (inStopId && outStopId && serviceId) {
      const source$ = this.bookingsHttpService.isExceedingKmLimit(userId, inStopId, outStopId, serviceId);
      return await lastValueFrom(source$);
    }
    return false;
  }

  getData(response: any) {
    return response.data;
  }
}
