import { Component, OnInit } from '@angular/core';
import { LanguageService } from './services/language/language.service';
import { StorageService, LiteralService, NotificationService, UsersService, JWTTokenService } from './services';
import { initFlowbite } from 'flowbite';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NotificationTypes, UserSortType } from './models/enums';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { firebaseConfig } from 'src/environments/environment';
import { Modal } from 'flowbite';
import { images } from 'src/images';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public title = 'Backoffice client';
  public initialized = false;

  public password = '';
  public confirmPassword = '';
  public changingPassword = false;
  public changePasswordModalGeneral: Modal;

  public routeName = '';

  constructor(
    private languageService: LanguageService,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private literalService: LiteralService,
    private usersService: UsersService,
    private jwtTokenService: JWTTokenService,
    private route: ActivatedRoute,
    private router: Router,
    public location: Location,
  ) {
    this.getRouteName();
  }

  async ngOnInit() {
    const token = await this.storageService.getToken();
    if (token) {
      const path = this.route.routeConfig?.path;
      if (token && path && (localStorage.getItem('route') !== path || !localStorage.getItem('route'))) {
        await this.setRouteName(path);
        this.router.navigate([localStorage.getItem('route')]);
      }
    } else {
      await this.setRouteName('users');
    }
    const browserLocale = navigator.language.split('-')
      ? navigator.language.split('-')[0]
      : navigator.language;
    this.languageService.setDefaultLanguage(
      (await this.storageService.getLanguage()) || browserLocale,
    );
    this.languageService.setLanguage(
      (await this.storageService.getLanguage()) || browserLocale,
    );
    this.setDefaultData();
    initFlowbite();
    this.initialized = true;
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }

  public async changePassword(password: string) {
    this.changingPassword = true;
    const userId = Number(this.jwtTokenService.getId());
    await this.usersService.changePassword(userId, password).then(() => {
      this.notificationService.image = images.user.password;
      this.notificationService.title = this.literalService.get(
        'users.actions.changePassword.passwordChangedSuccessTitle',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'users.actions.changePassword.passwordChangedSuccessText',
        true,
      );
      this.notificationService.show(NotificationTypes.SUCCESS);
    }, () => {
      this.notificationService.image = images.user.password;
      this.notificationService.title = this.literalService.get(
        'users.actions.changePassword.passwordChangedErrorTitle',
        true,
      );
      this.notificationService.message = this.literalService.get(
        'users.actions.changePassword.passwordChangedErrorText',
        true,
      );
      this.notificationService.show(NotificationTypes.DANGER);
    });
    this.changingPassword = false;
  }

  setDefaultData = async () => {
    await this.storageService.setUserSortType(UserSortType.CREATION);
  };

  public showNotification = () => {
    return true;
    // return configureStore.getState().notification.show
  };

  public async setRouteName(routeName: string) {
    this.routeName = routeName;
    await this.storageService.setRouteName(routeName);
    this.getRouteName();
  }

  public async getRouteName() {
    await this.storageService.getRouteName() || '';
    this.routeName = await this.storageService.getRouteName() || '';
  }
}
