<div [id]="id" tabindex="-1"
    class="modal fixed top-0 left-0 right-0 z-50 hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-96 max-w-md max-h-full rounded-lg">
        <div class="relative primary-purple-light-2-background rounded-lg shadow dark:bg-gray-700">
            <div class="p-10">
                <form #form novalidate class="text-center" (ngSubmit)="onSubmit()">
                    <img [src]="img" class="h-[60px] w-full mb-[50px] justify-center items-center" />
        
                    <h2 class="text-[18px] text-center font-bold  dark:text-gray-400 mb-[10px]">{{ title }}</h2>
                    <label class="text-base text-center font-normal mb-10 dark:text-gray-400">{{ text}}</label>
                    <label class="text-base text-center font-normal primary-purple-light-text"> {{ formatDate(penaltyDate) }}</label>
                    @if (penaltyReason) {
                        <h2 class="text-[18px] text-center font-bold dark:text-gray-400 mt-6 mb-[10px]">{{ this.literalService.get('users.actions.Blocked.reasonTitle', true)}}</h2>
                        <label class="text-base text-center font-normal mb-12 dark:text-gray-400">{{ penaltyReason }}</label>
                    }
                    <input type="submit" data-modal-hide="blockedUserBooking"
                        class="secondary gap-4 p-2 border-2 font-semibold h-12 w-full uppercase cursor-pointer mt-6"
                        [value]="literalService.get('bookings.actions.makeBookingAnyway.title', true)" />
                    <input type="button" (click)="action.emit()" data-modal-hide="blockedUserBooking"
                        class="secondary gap-4 p-2 border-2 font-semibold h-12 w-full uppercase cursor-pointer mt-[15px]"
                        [value]="literalService.get('backToPreviousPage', true)" />
                </form>
            </div>

        </div>
    </div>
</div>