import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { IBookingDetails } from 'src/app/models/bookings/bookingDetails';
import { LiteralService } from 'src/app/services';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-notified-booking',
  templateUrl: './modal-notified-booking.component.html',
  styleUrl: './modal-notified-booking.component.scss'
})
export class ModalNotifiedBookingComponent {

  @Input() id: string;
  @Input() modal: Modal;
  @Input() booking: IBookingDetails;
  @Output() action = new EventEmitter<boolean>();

  public images = images;

  constructor(public literalService: LiteralService) {}

}
