import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputType } from 'src/app/models/enums';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';
import countries from '../../../countries.json';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() data: any;
  @Input() edit?: boolean;
  @Input() independent?: boolean;
  @Input() translateValue?: boolean = false;
  @Input() hasErrors?: boolean = false;
  @Input() error?: string = '';
  @Output() changeEventEmitter = new EventEmitter<any>();
  @Output() setEventEmitter = new EventEmitter<any>();
  @Output() scrollEventEmitter = new EventEmitter<any>();
  @Output() searchEventEmitter = new EventEmitter<any>();

  public InputType = InputType;
  public countries: any[] = [];
  public dialCodes: any[] = [];
  public images = images;
  public dialCodeSelected = '+34';
  public dialCodeCountry = 'ES';

  public timeout = setTimeout(() => {}, 0);

  constructor(public literalService: LiteralService) {
    countries.map((country: any) => {
      const element = {
        id: country.dial_code,
        image: country.emoji,
        name: country.dial_code,
      };
      this.countries.push(element);
      this.dialCodes.push(country.dial_code);
    });
  }

  ngOnInit(): void {
    console.log(!this.data.img && !this.data.title);
    console.log(this.data);
    if (this.data.edit?.inputType === InputType.PHONE) {
      this.dialCodeSelected = this.dialCodes.find((option: string) => {
        return this.data.value && this.data.value.includes(option, 0);
      });
      this.data.value = this.data.value?.replace(this.dialCodeSelected, '');
      this.dialCodeCountry =
        countries.find(
          (country: any) => country.dial_code === this.dialCodeSelected,
        )?.dial_code || '+34';
    }
  }

  change(event: any) {
    this.changeEventEmitter.emit(event);
  }

  cancelEvent = (event: any)  => {
    event.preventDefault();
  };

  searchSelect2 = (event: any) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.searchEventEmitter.emit(event), 500);
  };
}
