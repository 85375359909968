import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Modal } from 'flowbite';
import { IBookingDetails } from 'src/app/models/bookings/bookingDetails';
import { InputType } from 'src/app/models/enums';
import { BookingsService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-change-booking-kms',
  templateUrl: './modal-change-booking-kms.component.html',
  styleUrl: './modal-change-booking-kms.component.scss',
})
export class ModalChangeBookingKmsComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() kms: number = 0;
  @Input() booking: IBookingDetails;
  @Output() action = new EventEmitter<any>();

  public InputType = InputType;
  public images = images;

  public buttonDisabled = false;

  public changeDriverFormControl: FormGroup;
  public hasErrors: boolean = false;
  public error: string = '';

  constructor(public literalService: LiteralService, public bookingsService: BookingsService) {}

  change = (event: any) => {
    this.booking.kms = event;
    this.kms = event;
  };

  onSubmit = async () => {
    this.action.emit(this.kms);
    this.modal.toggle();
  };
}
